<template>
    <span v-if="tags.length > 0" class="tag text-nowrap" v-for="(tag, index) in tags" :key="tag">{{ tag }}
        <!-- Small screen -->
        <span class="d-lg-none">
            <a href="#" class="ms-1" @click="delRole(tag, index)"><fa icon="xmark" /></a>
        </span>
        <!-- Big screen -->
        <span class="tagRemove" @click="delRole(tag, index)"> x </span>
    </span>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
export default {
    name: 'Tag',
    props: [ 'tags' ],
    emits: ['getTags', 'showRoleInput'],
    setup (props, { emit }) {

        const tags = ref(props.tags)

        watch([tags.value], () => {
            // console.info('Tag:watch', tags.value)
            emit('getTags', tags.value)
        })

        const delRole= (val, index) => {
            // console.info('Tag:delRole', val)
            tags.value.splice(index, 1)
            emit("showRoleInput")
        }

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
         })

        return { tags, delRole }
    }
}
</script>
// With drop down menu
// Only allow 1 tag, not allow multiple tags
// Tag option in other table 

<style>
.tag {
    background-color: #6E777E;
    color:#ffffff;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 4px 12px 6px 12px;
    border-radius: 15px;
    font-size: 12px;
}

.tagRemove {
    color: #6E777E;
    background-color: #f8f9fa;
    border-radius: 20px;
    padding: 0px 6px 3px 4px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}
</style>