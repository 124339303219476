<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="2" disabledSignee="false" :disabledSignatory="disabledSignatory" :disabledReview="disabledReview"/>
    
    <div class="border shadow-sm mainBox mainBox55">

        <div class="row mb-3" v-if="getProjectName">
            <div class="col-12 fw-bold">{{ getProjectName }}</div>
        </div>

        <div class="row mb-3">
            <div class="col-md-9">
                <span>You may include all the signees (participants) in the folder</span>
            </div>
            <div class="col-md-3 text-end pe-3">
                <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddSignee" @click="resetSignee" class="text-primary isLink">
                    <fa icon="plus" class="me-2"/>Add Signee
                </a>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-hover">
                    <tbody>
                        <tr v-for="s in signees" :key="s.signeeId">
                            <td class="align-middle">
                                <div class="row">
                                    <!-- <div class="col-12 col-lg-5 pt-1">{{s.email}}</div> -->
                                    <div class="col-12 col-lg-8 align-text-top">
                                        <table>
                                            <tr>
                                                <td class="pe-2">
                                                    <Avatar :isUser="s.isUser" :avatar="s.avatar" displayName="" :isVerified="true" class="fw-bold" width="35" height="35" />
                                                </td>
                                                <td class="ms-1 align-middle">
                                                    <div class="fw-bold">{{s.name}}</div>
                                                    <div>{{s.email}}</div>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- <span v-if="s.isUser"><img :src="s.avatar" class="rounded-circle me-2" width="35" height="35" style="object-fit: cover"/></span>
                                        <span v-if="!s.isUser"><fa icon="circle-user" class="me-2" style="width: 35px; height: 35px;" /></span>
                                        <span class="ms-1 fw-bold align-text-top border" >{{s.name}}</span> -->
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <table width="100%" border="0">
                                            <tr>
                                                <td>
                                                    <!-- <span class="badge bg-secondary">{{s.role}}</span> -->
                                                    <span class="badge bg-grey">{{s.role}}</span>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                            <fa icon="ellipsis-h" class="text-secondary"/>
                                                        </a>
                                                        <ul class="dropdown-menu dropdown-menu-secondary">
                                                            <li><a class="dropdown-item" href="#" @click="modifySignee(s, index)" data-bs-toggle="modal" data-bs-target="#mdlAddSignee">Modify</a></li>
                                                            <li><a class="dropdown-item" href="#">Add Files</a></li>
                                                            <li><a class="dropdown-item" href="#" @click="delSignee(s, s.signeeId)">Remove</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="totalSignee === 0 || !isMeSignee" class="row mb-3">
            <div class="col-6">
                <span v-if="totalSignee === 0" class="text-primary isLink" @click="addMeOnlySignee">I'm the only signee</span>
            </div>
            <div v-if="!isMeSignee" class="col-6 text-end">
                <span class="text-primary isLink" data-bs-toggle="modal" data-bs-target="#mdlAddSignee" @click="addMeSignee">Add me as signee</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                All signees will receive an email invitation to sign/view the documents, and the signing process is not reversible. <br>The final signed copy will be distributed to all the signees and archived in our secure file vault.
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr class="my-4"/>
            </div>

            <div class="col-12 text-center mb-1">
                <button class="btn btn-secondary" @click="$router.push({ path: '/pdf/detail' })">
                    <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                </button> 
            </div>
        </div>

    </div>

    <!-- Modal --> 
    <div
        ref="RefModalSample"
        class="modal fade"
        id="mdlAddSignee"
        tabindex="-1"
        aria-labelledby="AddSignee"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" :class="signeeId === null ? 'modal-lg' : ''">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="signeeId === null" class="modal-title" id="exampleModalLabel"> Add Signee</h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel"> Modify Signee</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div :class="signeeId === null ? 'col-12 col-lg-7' : 'col-12'">
                            <div class="row">
                                <input type="hidden" class="form-control" v-model="signeeId" >
                                <div class="col-12 mb-3">
                                    <input type="email" class="form-control" v-model="signeeEmail" placeholder="Email" :disabled="signeeId !== null">
                                </div>
                                <div class="col-12 mb-3">
                                    <input type="text" class="form-control" v-model="signeeName" placeholder="Name">
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label text-secondary">Role (E.g. BUYERWITNESS, SELLERWITNESS)</label>
                                    <div v-if="roleList.length === 0" class="custom-dropdown" @click="setRoleFocus">
                                        <Tag :tags="roleList" @getTags="getTags" @showRoleInput="showRoleInput" />
                                        <input type="text" v-model="role" ref="roleRef" class="role" autocomplete="off"
                                            @input="roleChange" @keyup.enter="enterRole()" autofocus style="text-transform: uppercase" />
                                        <ul class="dropdown-menu dropdown-menu-secondary" ref="roleOptRef">
                                            <li class="dropdown-item" v-for="(role, idx) in roleOption" :key="role">
                                                <span @click="selectRole(role)" class="pe-3 text-decoration-none" style="cursor: pointer">{{role}}</span>
                                                <fa icon="trash" class="float-end isLink text-white pt-1" @click="delRoleOption(role, idx)"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="custom-dropdown" >
                                        <Tag :tags="roleList" @getTags="getTags" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Signee list -->
                        <div v-if="signeeId === null" class="col-12 col-lg-5">
                            <div class="row">
                                <!-- <div class="col-12 text-end mb-1 lblTiny">
                                    <span class="filter-item" :class="filSig === 'recent' ? 'active' : ''" @click="setFilter('recent')">Recent</span>
                                    <span class="filter-item" :class="filSig === 'freq' ? 'active' : ''"  @click="setFilter('freq')">Frequent</span>
                                </div> -->

                                <div class="col-12 mb-2">
                                    <div class="border shadow-sm smallBox">
                                        <div class="mb-2 p-0">
                                            <table width="100%">
                                                <tr>
                                                    <td><span class="filter-item" :class="filSig === 'recent' ? 'active' : ''" @click="setFilter('recent')">Recent</span></td>
                                                    <td><span class="filter-item" :class="filSig === 'freq' ? 'active' : ''"  @click="setFilter('freq')">Frequent</span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <input type="text" class="form-control mt-2" placeholder="Name / Email / Tag" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div>
                                            <table class="table table-sm table-hover">
                                                <tbody>
                                                    <tr v-for="r in arrList" :key="r">
                                                        <td class="isLink" @click="selectSignee(r)">
                                                            <div>{{r.displayName}}</div>
                                                            <div class="lblTiny">{{r.email}}</div>
                                                            <div>
                                                                <span class="badge tag isLabel" v-for="t in r.tags" :key="t">
                                                                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-12">
                                    <div class="border shadow-sm smallBox">
                                        <div><u>Recent signees</u></div>
                                        <div>
                                            <table class="table table-sm table-hover">
                                                <tbody>
                                                    <tr v-for="r in arrRecent" :key="r">
                                                        <td>
                                                            <div>{{r.displayName}} <span class="text-grey"><fa icon="at" class="ms-1"/>{{r.nickName}}</span></div>
                                                            <div class="lblTiny">{{r.email}}</div>
                                                            <div>
                                                                <span class="badge tag isLabel" v-for="t in r.tags" :key="t">
                                                                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="border shadow-sm smallBox">
                                        <div><u>Frequent signees</u></div>
                                        <div>
                                            <table class="table table-sm table-hover">
                                                <tbody>
                                                    <tr v-for="r in arrFreq" :key="r">
                                                        <td>
                                                            <div>{{r.displayName}} <span class="text-grey"><fa icon="at" class="ms-1"/>{{r.nickName}}</span></div>
                                                            <div class="lblTiny">{{r.email}}</div>
                                                            <div>
                                                                <span class="badge tag isLabel" v-for="t in r.tags" :key="t">
                                                                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="signeeId === null" type="button" @click="addSignee" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" size="lg" class="me-2" />Save
                    </button>
                    <button v-else type="button" @click="updSignee" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="rotate" size="lg" class="me-2" />Update
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End --> 

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import { ref, inject, onMounted, watch } from 'vue'
// import axios from '@/functions/axios-instance'
import { useStore } from '@/stores/store'
import { useRouter, useRoute } from 'vue-router'
import draggable from 'vuedraggable'
import Alert from '@/components/Alert.vue'
// import Popper from 'vue3-popper'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import Tag from '@/components/Tag.vue'
import Avatar from '@/components/Avatar.vue'
import funcs from '@/functions/function'

export default {
    name: 'AddSignee',
    components: {
        TopNavigation, draggable, Alert, Tooltip, Tag, Avatar
    },
    setup() {
        const alert = ref([])
        const axios = inject('axios')
        const func = funcs
        const route = useRoute()
        
        const inSequence = ref(false)
        const dragging = ref(false)
        const signees = ref([])
        const signeeEmail = ref(null)
        const signeeName = ref(null)
        const signeeRole = ref(null)
        const signeeId = ref(null)
        const signeeIndex = ref(null) // edit signee
        const totalSignee = ref(0)
        const projectId = ref(null)
        const project = ref([])
        const classAll = ref('btn btn-sm btn-secondary active')
        const classRestrict = ref('btn btn-sm btn-secondary')
        const isMeSignee = ref(false)

        const role = ref(null)
        const roleRef = ref(null)
        const roleOptRef = ref(null)
        const roleList = ref([])
        const roleOption = ref([])

        const arrList = ref([])
        const filSig = ref('recent')

        const disabledSignatory = ref(true)
        const disabledReview = ref(true)

        const router = useRouter()
        const { getProjectName, getUserId, getDisplayName } = useStore()
        const store = useStore()

        projectId.value = store.getProjectId.value

        // totalSignee.value = signees.value.length
        // 'Authorization': 'Bearer '+ store.getSessionId.value
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const getSignees = () => {

            isMeSignee.value = false
            axios.get( '/signon/' + store.getProjectId.value + '/signee/list'
                ).then((res) => {
                    if (res.data.status === 1) {
                        console.info('getSignees', JSON.stringify(res.data.data))
                        signees.value = res.data.data
                        totalSignee.value = res.data.data.length

                        if (signees.value !== []) {
                            // console.info('== has Signees')
                            signees.value.forEach((item, idx) => {
                                if (item.email === getUserId.value) {
                                    isMeSignee.value = true
                                }
                            })
                        }

                        setTimeout(() => setPermission(), 1000)
                        // console.info('totalSignee', totalSignee.value)
                    } 
                })
                .catch((error) => {
                    console.log('getSignees | FAILURE', JSON.stringify(error))
                })
        }

        const setPermission = () => {
            if (totalSignee.value === 0) {
                disabledSignatory.value = true
                disabledReview.value = true
            } else {
                disabledSignatory.value = false
                disabledReview.value = false
            }
        }

        const addSignee = async () => {
            
            const p = {
                email: signeeEmail.value, 
                name: signeeName.value,
                role: roleList.value[0] === undefined ? '' : roleList.value[0]
            }   

            axios.post( '/signon/' + store.getProjectId.value + '/signee', p
            ).then((res) => {
                console.info('addSignee', JSON.stringify(res.data.data))

                if (res.data.status === 1) {
                    
                    getSignees()    
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Signee is added."
                    })
                } 
            })
            .catch((error) => {

                console.log('addSignee ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
            resetSignee()
        }

        const modifySignee = (signee, index) => {

            signeeIndex.value = index
            signeeName.value = signee.name
            signeeEmail.value = signee.email
            signeeId.value = signee.signeeId

            roleList.value = []
            if (signee.role !== '') {
                roleList.value.push(signee.role)
            }
        }

        const updSignee = () => {

            const p = {
                name: signeeName.value,
                role: roleList.value[0] === undefined ? '' : roleList.value[0]
            }
            // console.info('upd', JSON.stringify(p))
            axios.put( '/signon/' + store.getProjectId.value + '/signee/' + signeeId.value, p
            ).then((res) => {

                if (res.data.status === 1) {
                    getSignees()    
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Signee is updated."
                    })
                } 
            })
            .catch((error) => {

                console.log('addSignee ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const delSignee = (signee, id) => {
            // signees.value.splice(idx, 1)
            axios.delete( '/signon/' + store.getProjectId.value + '/signee/' + id
            ).then((res) => {

                if (res.data.status === 1) {
                    getSignees()    
                    resetSignee()
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Signee is removed."
                    })
                } 
            })
            .catch((error) => {

                console.log('delSignee ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const resetSignee = () => {
            signeeName.value = null
            signeeEmail.value = null
            signeeRole.value = null
            signeeId.value = null
            roleList.value = []            
        }

        const addMeSignee = async () => {
            signeeEmail.value = store.getUserId.value
            signeeName.value = getDisplayName.value
            // display signeeName, roleList   
        }

        const addMeOnlySignee = async () => {
            signeeEmail.value = getUserId.value
            signeeName.value = getDisplayName.value

            addSignee()

        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const selectRole = (val) => {
            console.info('selectRole', val)
            roleList.value.push(val)
            role.value = null
            // roleRef.display = "none"
            signeeRole.value = val
        }

        const getTags = (tags) => {
            roleList.value = tags
        }

        const roleChange = () => {
            if (!roleOptRef.value.classList.value.includes('show')) {
                roleOptRef.value.classList.add('show')
            }
        }

        const enterRole = () => {
            if (role.value.length > 0) {
                const p = {
                    signeeRole: role.value.toUpperCase()
                }

                axios.post( '/signon/signeeRole', p
                ).then((res) => {
                    if (res.data.status === 1) {
                        roleOption.value.push(role.value.toUpperCase())
                        roleList.value.push(role.value.toUpperCase())

                        setTimeout(() => {
                            role.value = null
                        }, 500)
                    } 
                })
                .catch((error) => {

                    console.log('addSigneeRole | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })
            }
        }

        const setRoleFocus = () => {
            if (roleList.value.length === 0 ) {
                roleRef.value.focus()

                if (roleOptRef.value.classList.value.includes('show')) {
                    roleOptRef.value.classList.remove('show')
                } else {
                    roleOptRef.value.classList.add('show')
                }
            }
        }

        const delRoleOption = (val, idx) => {
            axios.delete( '/signon/signeeRole/' + val
            ).then((res) => {
                if (res.data.status === 1) {
                    roleOption.value.splice(idx, 1)
                } 
            })
            .catch((error) => {
                console.log('getProject | FAILURE', JSON.stringify(error))
            })
        }

        const setFilter = (opt) => {
            filSig.value = opt

            if (opt === 'freq') {

                arrList.value = [
                    {
                        "signeeId": "6A831A38E24E11EC89D6FB54B2806B98",
                        "email": "ksksks@hotmail.com",
                        "displayName": "KS Lee",
                        "nickName": "KS",
                        "isUser": false,
                        "isVerified": false,
                        "tags": [
                            "MCIS",
                        ]
                    },
                    {
                        "signeeId": "DED17C76E24F11EC89D6FB54B2806B98",
                        "email": "ynlim@yahoo.com",
                        "displayName": "YN.Lim",
                        "nickName": "YNLim",
                        "isUser": true,
                        "isVerified": true,
                        "tags": [
                            "colleague"
                        ]
                    },
                    {
                        "signeeId": "6E7A09F8E24E11EC89D6FB54B2806B98",
                        "email": "selina.88@domain.com",
                        "displayName": "Selina Chan",
                        "nickName": "Selinae",
                        "isUser": false,
                        "isVerified": false,
                        "tags": [
                            "MCIS",
                            "colleague"
                        ]
                    }
                ]

            } else {
                arrList.value = [
                    {
                        "signeeId": "E3568F3EE24F11EC89D6FB54B2806B98",
                        "email": "alice.yong@gmail.com",
                        "displayName": "Alice Yong",
                        "nickName": "Alice",
                        "isUser": true,
                        "isVerified": false,
                        "tags": [
                            "alice",
                            "colleague"
                        ]
                    },
                    {
                        "signeeId": "DED17C76E24F11EC89D6FB54B2806B98",
                        "email": "yling88@yahoo.com",
                        "displayName": "Y.L.Tan",
                        "nickName": "Yee Ling Tan",
                        "avatar": "https://lh3.googleusercontent.com/a/AATXAJy75Od2bWhR_ZCFPKNSYe9HMOHENV6QEJcMGd1K=s96-c",
                        "isUser": true,
                        "isVerified": true,
                        "tags": [
                            "colleague"
                        ]
                    },
                    {
                        "signeeId": "6E7A09F8E24E11EC89D6FB54B2806B98",
                        "email": "patrickleong@domain.com",
                        "displayName": "Patrick Leong",
                        "nickName": "Patrick",
                        "isUser": false,
                        "isVerified": false,
                        "tags": [
                            "projectAA",
                            "colleague"
                        ]
                    },
                    {
                        "signeeId": "6A831A38E24E11EC89D6FB54B2806B98",
                        "email": "ksksks@hotmail.com",
                        "displayName": "KS Lee",
                        "nickName": "KS",
                        "isUser": false,
                        "isVerified": false,
                        "tags": [
                            "projectAA",
                        ]
                    }
                ]
            }
        }

        const selectSignee = (s) => {
            signeeEmail.value = s.email
            signeeName.value = s.displayName
        }

        onMounted(() => {
            // initial tooltip
            Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))


            /* var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            }) */

            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    console.info('detect query l', route.query.l)
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            getSignees()
            setFilter('recent')

            // console.info('config', config)
            if (store.getProjectId.value) {
                let url = '/signon/' + store.getProjectId.value
                axios.get( url
                ).then((res) => {
                    if (res.data.status === 1) {
                        project.value = res.data.data
                    } 
                })
                .catch((error) => {
                    console.log('getProject | FAILURE', JSON.stringify(error))
                })
            }


            // roleOption.value = ['Seller', 'Buyer', 'Lawyer Bank', 'Lawyer Seller']

            axios.get( '/signon/signeeRole/list'
            ).then((res) => {
                if (res.data.status === 1) {
                    roleOption.value = res.data.data
                } 
            })
            .catch((error) => {
                console.log('getRoleOpt | FAILURE', JSON.stringify(error))
            })

        }) 

        return { 
            alert, closeAlert, inSequence, dragging, signees, store, router, 
            delSignee, addSignee, modifySignee, updSignee, resetSignee, getSignees, 
            signeeEmail, signeeName, signeeRole, signeeId, signeeIndex,
            addMeSignee, addMeOnlySignee, getUserId, totalSignee,
            getProjectName, projectId, project,
            classAll, classRestrict, 
            role, roleRef, roleOption, roleOptRef, roleList, selectRole, getTags, roleChange, enterRole, setRoleFocus, delRoleOption,
            disabledSignatory, disabledReview, isMeSignee, func,
            arrList, filSig, setFilter, selectSignee
        }
    },
    
}
</script>
// https://www.npmjs.com/package/vue3-form-render-vuedraggable
// npm i -S vuedraggable@next

<style>
.button {
  margin-top: 35px;
}
.handle {
  cursor: move;
}
.close {
  cursor: pointer;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}

.custom-dropdown {
    text-align: left;
    display: block;
    width: 100%;
    padding: 4px 5px;
    font-size: 1rem;
    font-weight: 400;
    height: 40px;
    /* line-height: 1.5; */
    color: #212529;
    /* background-color: #fff; */
    background-color: var(--bgcolor-body-color);
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border: 1px solid var(--accent-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.role {
    width: 80px;
    height: 30px;
    font-size: 12px;
    color: var(--form-control-text);
    background-color: var(--bgcolor-body-color);
    border: 1px solid var(--bgcolor-body-color);
}

.role:focus { 
    outline: none; 
    color: var(--form-control-text);
    background-color: var(--bgcolor-body-color);
}

.circle
{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid;
  background-image:
    linear-gradient(18deg, white 50%, transparent 50%),
    linear-gradient(-90deg, red 50%, transparent 50%);
}

.smallBox {
  border-radius: 10px;
  padding: 25px;
  /* margin-bottom: 25px; */
  margin-left: 20px;
  margin-right: 2px;
  background-color: var(--auth-box-bg);
}

@media (max-width: 500px) {
    .smallBox {
      border-radius: 10px;
      background-color: var(--auth-box-bg);
      margin-left: 2px;
    }
}

.filter-item {
    background: none;
    margin: 5px 5px;
    padding: 5px;
}

.filter-item.active {
  color: var(--bs-primary);
  font-weight: bold;
  border-bottom: 2px solid var(--bs-primary);
}

.filter-item.active:hover {
  color: var(--bs-primary);
  font-weight: bold;
  border-bottom: 2px solid var(--bs-primary);
  cursor: text;
}

.filter-item:hover {
  color: var(--bs-primary);
  cursor: pointer;
} 
</style>